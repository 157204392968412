const object_message_type= [
  { id: "IFTMCS", name: "bookings"},
  { id: "IFTMBFD06A", name: "tiBillOfLadings" },
  { id: "IFTMBC", name: "tiBillOfLadings"},
  { id: "CODECO", name: "Codeco" },
  { id: "COARRI", name: "Coarri" },
  { id: "VERMAS", name: "Vermas" },
  { id: "COPARN", name: "Ot" },
  { id: "COPRAR", name: "COPRAR"},
] as any;

export default object_message_type;
