
import { useI18n } from "vue-i18n";
import { onMounted, computed, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

import object_message_type from "@/core/data/mesagge_type";
export default {
  name: "MessagingTypeMessagingSelect",
  props: {
    multiselect: {
      type: Boolean,
      default: false,
    },
    message_type: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
  },
  emits: ["update:message_type"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const values_select = computed(() => {
      let datas = [] as any;
      object_message_type.forEach((value) => {
        datas.push({ id: value.id, name: t(value.name) });
      });
      return datas;
    });
    const element_id = ref("");
    const inputTemplate = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const getTemplate = () => {
      store.commit("setLoading", false);
      inputTemplate.value.list = values_select;
      inputTemplate.value.options = values_select;
    };

    const selectTemplate = (query) => {
      if (query !== "") {
        inputTemplate.value.loading = true;
        setTimeout(() => {
          inputTemplate.value.loading = false;
          inputTemplate.value.options = inputTemplate.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputTemplate.value.options = inputTemplate.value.list;
      }
    };

    watch(
      () => props.type_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      getTemplate();
      setTimeout(function () {
        element_id.value = props.type_id;
      }, 500);
    });

    return {
      element_id,
      values_select,
      inputTemplate,
      selectTemplate,
    };
  },
};
