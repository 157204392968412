
import { useI18n } from "vue-i18n";
import { onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default {
  name: "MessagingStateSelect",
  props: {
    multiselect: {
      type: Boolean,
      default: false,
    },
    state_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
  },
  emits: ["update:state_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const values_select = ref([
      { id: 1, name: t("ipending") },
      { id: 2, name: t("iread") },
      { id: 3, name: t("iincorporating") },
      { id: 4, name: t("iEdiError") },
    ]);
    const element_id = ref("");
    const inputTemplate = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const getTemplate = () => {
      store.commit("setLoading", false);
      inputTemplate.value.list = values_select;
      inputTemplate.value.options = values_select;
    };

    const selectTemplate = (query) => {
      if (query !== "") {
        inputTemplate.value.loading = true;
        setTimeout(() => {
          inputTemplate.value.loading = false;
          inputTemplate.value.options = inputTemplate.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputTemplate.value.options = inputTemplate.value.list;
      }
    };

    watch(
      () => props.state_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      getTemplate();
      setTimeout(function () {
        element_id.value = props.state_id;
      }, 500);
    });

    return {
      element_id,
      values_select,
      inputTemplate,
      selectTemplate,
    };
  },
};
