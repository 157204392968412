
import { onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "EquimpentSubtypesSelect",
  props: {
    equipment_subtype_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:equipment_subtype_id", "changeType"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const inputEquipmentSubtype = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isEquipmentSubtypeRequired = (value) => {
      if (props.required && !value) {
        return t("requipmentsubtype");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "equipmentSubtype",
      isEquipmentSubtypeRequired
    );

    const getEquipmentSubtype = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/equipment-subtypes/lists").then(({ data }) => {
        inputEquipmentSubtype.value.list = data.equipment_subtypes;
        inputEquipmentSubtype.value.options = data.equipment_subtypes;
      });
    };
    const selectEquipmentSubtype = (query) => {
      if (query !== "") {
        inputEquipmentSubtype.value.loading = true;
        setTimeout(() => {
          inputEquipmentSubtype.value.loading = false;
          inputEquipmentSubtype.value.options =
            inputEquipmentSubtype.value.list.filter(
              (item: Record<string, any>) => {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            );
          if (!inputEquipmentSubtype.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/equipment-subtypes/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              inputEquipmentSubtype.value.list = data.equipment_subtypes;
              inputEquipmentSubtype.value.options = data.equipment_subtypes;
            });
          }
        }, 200);
      } else {
        inputEquipmentSubtype.value.options = [];
      }
    };

    watch(
      () => props.equipment_subtype_id,
      (first) => {
        element_id.value = first;
        const selected = inputEquipmentSubtype.value.list.find(
          (x) => x.id === first
        );
        if (selected) {
          emit("changeType", selected.name);
        }
        if (
          !inputEquipmentSubtype.value.options
            .map((x) => x.id)
            .includes(first) &&
          inputEquipmentSubtype.value.options.length > 0
        ) {
          ApiService.get("/api/equipment-subtypes/" + first).then(
            ({ data }) => {
              inputEquipmentSubtype.value.options.push(data);
            }
          );
        }
      }
    );

    onMounted(() => getEquipmentSubtype());

    return {
      element_id,
      inputEquipmentSubtype,
      errorMessage,
      getEquipmentSubtype,
      selectEquipmentSubtype,
    };
  },
};
