
import { util } from "prismjs";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}
import { computed, onMounted, onUpdated, ref } from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Search } from "@element-plus/icons-vue";
import TableBasicSelectExpandableRow from "@/components/tables/TableBasicSelectExpandableRow.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import MessagingStateSelect from "@/components/catalogs-select/MessagingStateSelect.vue";
import MessagingTypeSelect from "@/components/catalogs-select/MessagingTypeSelect.vue";
import EquimpentSubtypesSelect from "@/components/catalogs-select/EquimpentSubtypesSelect.vue";
import MessagingTypeMessagingSelect from "@/components/catalogs-select/MessagingTypeMessagingSelect.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import type = util.type;
import i18n from "@/core/plugins/i18n";
export default {
  name: "MessagingPanel",
  components: {
    TableBasicSelectExpandableRow,
    EquimpentSubtypesSelect,
    MessagingTypeMessagingSelect,
    MessagingStateSelect,
    MessagingTypeSelect,
    VueJsonPretty,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const header = ref([
      { column: "status_led", key: "status_led" },
      { column: t("imessagetype"), key: "message_type" },
      { column: t("iname"), key: "name" },
      { column: t("ifile"), key: "maritime_files_columns" },
      { column: t("ibookingNumber"), key: "booking" },
      { column: t("bills_of_lading"), key: "bl" },
      { column: t("isource"), key: "source" },
      { column: t("istate"), key: "status_name" },
      { column: t("itype"), key: "type" },
      { column: t("idate"), key: "created_at" },
    ]);
    const router = useRouter();
    const isLoading = ref(true);
    const seleted_id = ref("");
    const showDelete = ref(false);
    const search = ref("");
    const files = computed(() => store.getters.Files);
    const tableButtons = ref([]);
    const ediLogs = ref([]);
    const dataList = ref([] as any);
    const dataObject = ref([] as any);
    const dialogVisible = ref(false);
    const dialogDetailVisible = ref(false);
    const state_id = ref([]);
    const type_id = ref(["S"]);
    const start_date = ref([]);
    const message_type = ref([]);
    const end_date = ref("");
    const bodyEdiPath = ref("");
    const booking_code = ref("");
    const bl_code = ref("");
    const maritime_file_code = ref("");
    const filtter_name = ref("");
    const container_number = ref("");
    const container_type = ref([]);
    const container_type_id = ref([]);
    const itemID = ref("");
    const modalMode = ref("add");
    const activeAccordion = ref("1");
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: 10,
      last_page: 1,
      total: 1,
    });
    const serverSearch = () => {
      getAllItems();
    };

    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        const permissions = user.value.permissions_list;
        return (
          permissions.filter((x) => x.name === "vessel: create").length > 0
        );
      }
      return false;
    });
    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      serverSearch();
    };
    const currentPageChange = (val) => {
      pagination.value.page = val;
      serverSearch();
    };
    const canUpdate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        const permissions = user.value.permissions_list;
        return (
          permissions.filter((x) => x.name === "vessel: update").length > 0
        );
      }
      return false;
    });

    const canDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        const permissions = user.value.permissions_list;
        return (
          permissions.filter((x) => x.name === "vessel: delete").length > 0
        );
      }
      return false;
    });

    const forceDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        const permissions = user.value.permissions_list;
        return (
          permissions.filter((x) => x.name === "vessel: force_delete").length >
          0
        );
      }
      return false;
    });

    const createElement = () => {
      dialogVisible.value = true;
      itemID.value = "";
      modalMode.value = "add";
    };

    const getAllItems = () => {
      store.commit("setLoadingStatus", true);
      ApiService.query("/api/messaging_panel", {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          name: filtter_name.value,
          start_messaging_date:
            start_date.value === null || start_date.value.length === 0
              ? ""
              : start_date.value[0],
          end_messaging_date:
            start_date.value === null || start_date.value?.length === 0
              ? ""
              : start_date.value[1],
          messaging_status: state_id.value,
          messaging_type: type_id.value,
          mesagging_booking: booking_code.value,
          mesagging_bl: bl_code.value,
          mesagging_container_number: container_number.value,
          maritime_file_code: maritime_file_code.value,
          message_type_message: message_type.value,
        },
      }).then(({ data }) => {
        isLoading.value = false;
        dataList.value = data.messaging;
        dataObject.value = data.messaging;
        pagination.value.total = data.pagination.total;
        pagination.value.page = data.pagination.current_page;
        pagination.value.last_page = data.pagination.last_page;
        dataObject.value.forEach((value) => {
          value.name = value.filename.split(".")[0];
          value.created_at = formatDate(value.created_at);
          value.status_led =
            (value.status === 3 && value.type === "E") ||
            (value.status === 2 && value.type === "S")
              ? "success"
              : "danger";
          let maritime_files = "";
          value.row_key = `parent_${value.id}`;
          // value.hasChildren = value.children.length > 0;
          const result = [] as any;
          value.maritime_files.forEach((x) => {
            const element = result.find((y) => y.id === x.id);
            if (element === undefined) {
              result.push(x);
            }
          });
          console.log("gettAllItem result", result);
          value.maritime_files = result;
          value.maritime_files.forEach((x) => {
            x.url = `/maritimefile/${x.id}`;
          });
          value.status_name = formatState(value);
          value.type = formatType(value.type);
          value.maritime_files_columns = maritime_files;
          value.children.forEach((x) => {
            x.name = value.filename.split(".")[0];
            x.created_at = formatDate(x.created_at);
            let maritime_files = "";
            x.status_name = formatState(x);
            x.type = formatType(x.type);
            x.maritime_files_columns = maritime_files;
          });
        });
        console.log("getItems dataObject", dataObject.value);
        tableButtons.value = [
          {
            name: "Descargar" as never,
            type: "DOWNLOAD" as never,
            color: "primary" as never,
          } as never,
          {
            name: "Detalles" as never,
            type: "DETAILS" as never,
            color: "primary" as never,
          } as never,
          {
            name: "Ver" as never,
            type: "VIEW" as never,
            color: "primary" as never,
          } as never,
          {
            name: "Precess" as never,
            type: "PROCESS" as never,
            color: "primary" as never,
          } as never,
        ];
        isLoading.value = false;
        store.commit("setLoadingStatus", false);
      });
    };
    const getItems = async () => {
      if (files.value.length < pagination.value.rowsPerPage) {
        store.commit("setLoading", false);
        await store.dispatch("loadElements");
      }
    };

    const formatType = (date) => {
      if (date === "E") {
        date = t("tipoMessageEntrada");
      } else if (date === "S") {
        date = t("tipoMessageSalida");
      }
      return date;
    };
    const formatState = (date) => {
      if (date.type === "S") {
        return date.status_dictionary.outbound;
      } else {
        return date.status_dictionary.inbound;
      }
    };
    const formatDate = (date) => {
      return new Date(date).toLocaleString();
    };

    const resetModal = () => {
      dialogVisible.value = false;
      serverSearch();
    };

    onUpdated(() => {
      // console.clear();
    });

    onMounted(() => {
      // console.clear();
      setCurrentPageBreadcrumbs("imessaginpanel", [
        { route: "/others", label: "others" },
      ]);
      serverSearch();
    });

    const actionsButton = async (param1, param2, param3) => {
      let error = "";
      try {
        if (param2 == "DETAILS") {
          ediLogs.value = param1.edi_logs;
          dialogDetailVisible.value = true;
        }
        if (param2 == "VIEW") {
          isLoading.value = true;
          store.commit("setLoadingStatus", true);
          ApiService.get(`/api/messaging_panel/view/${param1}`)
            .then((data) => {
              console.log("data", data);
              let edi = data.data ? data.data : data;
              if (edi.ext === "application/pdf") {
                isLoading.value = false;
                window.open(edi.url);
              } else {
                isLoading.value = false;
                bodyEdiPath.value = edi.body;
                dialogVisible.value = true;
              }
              store.commit("setLoadingStatus", false);
            })
            .catch((error) => {
              console.log("Sd", error);
              Swal.fire({
                html: i18n.global.t("edipath_error"),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: i18n.global.t("tryAgain"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              store.commit("setLoadingStatus", false);
            });
        }
        if (param2 == "DOWNLOAD") {
          store.commit("setLoadingStatus", true);
          isLoading.value = true;
          ApiService.setResponseTypeBlob();
          ApiService.get(`/api/messaging_panel/download/${param1.id}`)
            .then((data) => {
              isLoading.value = false;
              let fileUrl = window.URL.createObjectURL(data);
              let fileLink = document.createElement("a");

              fileLink.href = fileUrl;
              fileLink.setAttribute(
                "download",
                `${
                  param1.path
                    ? param1.path.split("/")[1]
                    : param1.name
                    ? param1.name
                    : "File"
                }`
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              store.commit("setLoadingStatus", false);
            })
            .catch((error) => {
              Swal.fire({
                text: t("edipath_error"),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: t("tryAgain"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              console.log(error);
              store.commit("setLoadingStatus", false);
            });
          ApiService.setResponseTypeJson();
        }
        if (param2 == "PROCESS") {
          store.commit("setLoadingStatus", true);
          const params = { status: [param1.status], ids: [param1.id] };
          const { data } = await ApiService.post(
            "/api/edifile/process",
            params
          );
          resetModal();
        }
        if (param2 == "REMOVE") {
          seleted_id.value = param1;
          showDelete.value = true;
        }
      } catch (e) {
        store.commit("setLoadingStatus", false);
      }
    };

    const onSearch = () => {
      dataObject.value = dataList.value.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    const onLink = (index, current_element, row, event) => {
      /*store.commit("setMaritimeFileTab", {
        tab: event,
        item_id: current_element.id,
        from: "messagingpanle",
      });*/
      if (event === "overview") {
        router.push("/maritimefile/" + current_element.id);
      } else {
        console.log("onLink row", row);
        store.commit("setMaritimeFileTab", {
          tab: event === "ibookingNumber" ? "bookings" : "billOfLading",
          item_id: current_element.id,
          from: "container_mov",
        });
        if (event === "ibookingNumber") {
          localStorage.setItem("booking_id", current_element.id);
        } else {
          localStorage.setItem("bl_id", current_element.id);
        }
        router.push("/maritimefile/" + row.maritime_files[0].id);
      }
    };

    const doRemove = () => {
      store.commit("setLoadingStatus", true);
      ApiService.delete(
        `/api/prebillings/${seleted_id.value}?force_delete=${false}`
      )
        .then(async ({ data }) => {
          showDelete.value = false;
          seleted_id.value = "";
          await serverSearch();
          store.commit("setLoadingStatus", false);
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
        });
    };

    return {
      maritime_file_code,
      forceDelete,
      user,
      canCreate,
      canUpdate,
      canDelete,
      dataList,
      dataObject,
      dialogVisible,
      activeAccordion,
      bodyEdiPath,
      dialogDetailVisible,
      itemID,
      ediLogs,
      modalMode,
      message_type,
      type_id,
      start_date,
      end_date,
      booking_code,
      bl_code,
      container_number,
      container_type,
      container_type_id,
      pagination,
      state_id,
      header,
      tableButtons,
      isLoading,
      search,
      seleted_id,
      showDelete,
      filtter_name,
      onSearch,
      getAllItems,
      actionsButton,
      createElement,
      currentPageChange,
      setItemsPerPage,
      resetModal,
      serverSearch,
      onLink,
      doRemove,
    };
  },
};
