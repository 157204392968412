import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    modelValue: $setup.element_id,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.element_id) = $event)),
    name: "type",
    class: _normalizeClass(["custom-select w-100 p-0", !$props.multiselect ? 'chevron' : '']),
    size: $props.size,
    clearable: "",
    multiple: $props.multiselect,
    "collapse-tags": $props.multiselect,
    placeholder: `${_ctx.$t('iselect')}`,
    filterable: "",
    "reserve-keyword": "",
    loading: $setup.inputTemplate.loading,
    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:state_id', $event)))
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.inputTemplate.options, (item) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: item.id,
          label: `${item.id} ${item.name}`,
          value: item.id
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(`${item.id} ${item.name}`), 1)
          ]),
          _: 2
        }, 1032, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "class", "size", "multiple", "collapse-tags", "placeholder", "loading"]))
}