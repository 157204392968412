
import { computed, ref, watch } from "vue";
import { ElTable } from "element-plus";
import MaritimeVoyageSelect from "@/components/catalogs-select/MaritimeVoyageSelect.vue";
import { useStore } from "vuex";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  page_count: number;
}

interface IHeaderConfiguration {
  column?: string;
  key: string;
  sortingField?: string;
  sortable?: boolean;
}

export default {
  name: "TableBasicSelectExpandableRow",
  components: {
    MaritimeVoyageSelect,
  },
  emits: ["eventEmitButton", "onCellClick", "onLinkClick"],
  props: {
    headers: {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true,
    },
    headers_children: {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true,
    },
    use_check: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      required: true,
    },
    show_link: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 1,
    },
    page_count: {
      type: Number,
      default: 1,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
    canRoute: {
      type: Boolean,
      default: false,
    },
    canDuplicate: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    rowsPerPage: {
      type: Number,
      default: 10,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const element_id = ref("");
    const dialogvisible = ref(false);
    const dialogremovevisible = ref(false);
    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: 0,
      total: 1,
      page_count: 1,
    });
    watch(
      () => props.rowsPerPage,
      (selection, prevSelection) => {
        pagination.value.rowsPerPage = selection;
      }
    );
    watch(
      () => props.total,
      (selection, prevSelection) => {
        pagination.value.total = selection;
      }
    );
    watch(
      () => props.page_count,
      (selection, prevSelection) => {
        // console.log("watch props.page_count selection", selection);
        // console.log(
        //   "watch props.page_count props.page_count",
        //   props.page_count
        // );
        pagination.value.page_count = selection;
      }
    );
    const multipleSelection = computed(() => store.getters.TableSelectList);
    //const multipleSelection = ref<any[]>([]);
    const item_id = ref(null);
    const editButton = computed(
      () => props.buttons.filter((x) => x.type === "EDIT").length > 0
    );
    const processButton = computed(
      () => props.buttons.filter((x) => x.type === "PROCESS").length > 0
    );
    const viewButton = computed(
      () => props.buttons.filter((x) => x.type === "VIEW").length > 0
    );
    const detailsButton = computed(
      () => props.buttons.filter((x) => x.type === "DETAILS").length > 0
    );
    const generateEdiButton = computed(
      () => props.buttons.filter((x) => x.type === "GENERATE_EDI").length > 0
    );
    const duplicateButton = computed(
      () => props.buttons.filter((x) => x.type === "DUPLICATE-1").length > 0
    );
    const duplicate2Button = computed(
      () => props.buttons.filter((x) => x.type === "DUPLICATE-2").length > 0
    );
    const removeButton = computed(
      () => props.buttons.filter((x) => x.type === "REMOVE").length > 0
    );
    const activateButton = computed(
      () => props.buttons.filter((x) => x.type === "ACTIVATE").length > 0
    );
    const downloadButton = computed(
      () => props.buttons.filter((x) => x.type === "DOWNLOAD").length > 0
    );
    const hasEdiPath = (index) => {
      console.log("props.data", props.data);
      console.log("hasEdiPath", props.data[index]);
      return props.data[index] !== undefined
        ? props.data[index].edi_path_id
        : null;
    };

    const handleSizeChange = (val: number) => {
      // console.log(`${val} items per page`);
    };

    const handleCurrentChange = (val: number) => {
      // console.log(`current page: ${val}`);
      emit("onChangePage", val);
    };

    const toggleSelection = (rows?: any[]) => {
      if (rows) {
        rows.forEach((row) => {
          // TODO: improvement typing when refactor table
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          multipleTableRef.value!.toggleRowSelection(row, undefined);
        });
      } else {
        multipleTableRef.value!.clearSelection();
      }
    };
    const handleSelectionChange = (val: any[]) => {
      //multipleSelection.value = val;
      store.commit("setTableSelectList", val);
    };

    const onCLickCell = (val: any) => {
      store.commit("onCellClick", val);
    };

    const getItems = computed(() => {
      return JSON.parse(JSON.stringify(props.data));
    });

    const onClickEdit = (index, row) => {
      emit("eventEmitButton", row.id, "EDIT", index);
    };
    const onClickRoutes = (index, row) => {
      emit("eventEmitButton", row.id, "ROUTES");
    };
    const onClickView = (index, row) => {
      emit("eventEmitButton", row.id, "VIEW");
    };
    const onClickDetails = (index, row) => {
      emit("eventEmitButton", row, "DETAILS");
    };
    const onClickProcess = (index, row) => {
      emit("eventEmitButton", row, "PROCESS");
    };

    const onClickGenerateEdi = (index, row) => {
      emit("eventEmitButton", row.id, "GENERATE_EDI");
    };

    const onClickDuplicate = (index, row) => {
      item_id.value = row.id;
      dialogvisible.value = true;
    };

    const onClickDownload = (index, row) => {
      emit("eventEmitButton", row, "DOWNLOAD", index);
    };

    const onClickRemove = (index, row) => {
      item_id.value = row.id;
      // dialogremovevisible.value = true;
      emit("eventEmitButton", row.id, "REMOVE");
    };

    const onClickDuplicate2 = (index, row) => {
      emit("eventEmitButton", item_id, "DUPLICATE", row.id);
    };

    const duplicateElement = (element_id, item_id) => {
      dialogvisible.value = false;
      emit("eventEmitButton", item_id, "DUPLICATE", element_id);
    };

    const removeElement = (item_id, soft) => {
      dialogremovevisible.value = false;
      emit("eventEmitButton", item_id, "REMOVE", soft);
    };

    return {
      item_id,
      element_id,
      dialogvisible,
      dialogremovevisible,
      multipleTableRef,
      multipleSelection,
      getItems,
      detailsButton,
      editButton,
      processButton,
      viewButton,
      generateEdiButton,
      duplicateButton,
      duplicate2Button,
      removeButton,
      activateButton,
      pagination,
      downloadButton,
      hasEdiPath,
      onClickEdit,
      onClickRoutes,
      onClickGenerateEdi,
      onClickDuplicate,
      onClickRemove,
      onClickView,
      onClickDetails,
      onClickProcess,
      onClickDownload,
      onClickDuplicate2,
      duplicateElement,
      removeElement,
      toggleSelection,
      handleSelectionChange,
      handleSizeChange,
      handleCurrentChange,
      onCLickCell,
    };
  },
};
